import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en', // si la langue détectée n'existe pas, on tombe sur l'anglais
    resources: {
      en: {
        translation: {
          title: 'Hi, I\'m Quentin Lestrade,',
          subtitle: 'Lead (UX/UI)\nProduct Designer',
          stats: 'Years of experience',
          stats2: 'Project completed',
          stats3: 'Happy clients',
          stats4: 'Pixel grid',
          social: 'LinkedIn',
          social2: 'Dribbble',
          projects: {
              title: 'Take a look at my latest projects',
              seeAll: 'See all projects',
          },
          parcelchain: {
            title: 'ParcelChain',
            subtitle: 'Reimagining Delivery.',
            description: 'As Creative Director, I played a key role in shaping the identity and user experience of ParcelChain, a groundbreaking platform transforming last-mile delivery. My work encompassed both branding and UX/UI design, ensuring a cohesive and user-centric experience. By connecting commuters with parcel senders, ParcelChain makes deliveries faster, more sustainable, and more accessible.',
            brand: {
              title: 'Brand Design',
              intro: 'The ParcelChain brand was crafted to visually communicate the platform\'s core values: efficiency, security, and versatility. Every design element was carefully considered to create a logo that is not only memorable but also reflective of the app\'s purpose. The result is a dynamic and modern identity that resonates with both users and the ParcelChain mission.',
              callout1: 'The first element is an isometric view of a shipping box, where only two faces are visible, leaving the third face empty to create a «p» shape with the white space. This empty space also symbolizes the anoymity and security of the platform.',
              callout2: 'The second element is a comma, wich gives a sense of speed to the logo. This comma is actually a reverse «C» representing the initials «PC» for ParcelChain. The comma also sevesves as a reference to a boomerang, symbolizing the act that users can be both senders and recivers of parcels, as well as delivery drivers.',
              conclusion: 'The use of a shipping box in the logo emphasizes the app\'s focus on package delivery, while the comma adds a dynamic touch and reinforces the idea of a quick and reliable service.'
            },
            uxui: {
              title: 'UX/UI Design',
              description: 'The design of ParcelChain\'s user interface focused on delivering a seamless and intuitive experience for all users—whether they were sending parcels, receiving them, or acting as delivery drivers. Using Figma as the primary tool, I created a detailed and interactive prototype that leveraged variables to manage multilingual support, shipment statuses, and various user interactions. This approach ensured flexibility and adaptability across different use cases. Throughout the process, I collaborated closely with the backend and frontend development teams to align the design with technical requirements, resulting in a reliable, user-centered prototype that effectively bridged the gap between design and functionality.'
            }
          },
          dashstudio: {
            title: 'DashStudio',
            subtitle: 'Empowering Creatives.',
            description: 'With DashStudio, I single-handedly developed a web application tailored for photographers, videographers, and designers to manage their projects efficiently. It enables secure media sharing with clients and streamlines accounting tasks like creating quotes and invoices. Every feature is designed to enhance workflow and deepen client relationships.',
            development: {
              title: 'Development',
              description: 'The development of DashStudio is a solo endeavor, where I leverage the power of cutting-edge tools and artificial intelligence to bring the platform to life. Combining modern technologies such as HTML5, CSS3, React, Node.js, Docker, and Firebase, I\'ve built a robust and scalable application designed for creatives. AI plays a key role in optimizing workflows, generating efficient code, and ensuring seamless functionality. This combination allows me to tackle complex challenges independently while maintaining a high level of efficiency and innovation.'
            },
            brand: {
              title: 'Brand Design',
              intro: 'The DashStudio brand identity was meticulously crafted to embody innovation and reliability in creative project management. Each design element was carefully chosen to create a visual language that speaks to both professional creators and their clients, resulting in a brand that\'s both modern and trustworthy.',
              callout1: 'The three stripes represent the platform\'s core features: project management, client collaboration, and financial tools. Their upward angle symbolizes growth and progress, while their golden color evokes excellence and premium service.',
              callout2: 'The circular frame represents unity and completeness in workflow management. It\'s also reminiscent of a camera lens or viewfinder, connecting to the creative professionals who use the platform. The clean, minimalist design ensures the logo remains effective across all sizes and applications.',
              conclusion: 'Together, these elements create a brand identity that perfectly balances professionalism with creativity, reflecting DashStudio\'s role as an essential tool for modern creative professionals.'
            },
            uxui: {
              title: 'UX/UI Design',
              description: 'The UX/UI design of DashStudio was crafted with the needs of creatives in mind, ensuring a seamless and intuitive experience for photographers, videographers, and graphic designers. By focusing on the full project lifecycle—from quotes to delivery and payments—the design simplifies complex workflows into a user-friendly interface, enabling creatives to focus on what they do best.'
            }
          },
          davinci: {
            title: 'X-sphere | Da Vinci',
            subtitle: 'Providing Emotions.'
          },
          streetfear: {
            title: 'Street Fear',
            subtitle: 'Transcend Reality.'
          },
          why: {
            title: 'Why Work With Me?',
            card1: {
              title: 'User-Centric Approach',
              description: 'I put the user at the heart of every design decision, creating experiences that truly resonate.'
            },
            card2: {
              title: '360° Creative Vision',
              description: 'From video production to branding and UX/UI design, I bring a holistic perspective to every project.'
            },
            card3: {
              title: 'Multi-Disciplinary Expertise',
              description: 'My diverse skill set allows me to tackle challenges from multiple angles, delivering solutions that are both creative and effective.'
            }
          },
          contact: {
            title: 'Let\'s create something amazing together.',
            subtitle: 'Whether you have questions, want to discuss a project, or simply want to chat about design feel free to reach out. Drop me a message!',
            form: {
              name: 'Name',
              email: 'Email',
              message: 'Message',
              sending: 'Sending...',
              send: 'Send the message',
              success: 'Message sent successfully!',
              error: 'An error occurred. Please try again.'
            }
          },
          footer: {
            title: 'Stay in touch',
            description: 'I am currently located in Toulouse, France. If you\'re interested in connecting or discussing business over a coffee, feel free to get in touch with me.',
            download: 'Download my resume',
            contact: {
              title: 'Contact info',
              phone: '+33 6 34 53 17 48',
              email: 'contact@quentinlestrade.com',
              location: 'Toulouse, France'
            },
            social: {
              title: 'Connect with me',
              linkedin: 'LinkedIn',
              calendly: 'Calendly'
            }
          },
          projects: {
            title: 'Take a look at my latest projects',
            seeAll: 'See all projects',
            parcelchain: {
              title: 'Parcelchain',
              subtitle: 'Reimagining Delivery.'
            },
            dashstudio: {
              title: 'DashStudio',
              subtitle: 'Empowering Creatives.'
            },
            davinci: {
              title: 'X-sphere | Da Vinci',
              subtitle: 'Providing Emotions.'
            },
            streetfear: {
              title: 'Street Fear',
              subtitle: 'Transcend Reality.'
            },
            yourproject: {
              title: 'Your project',
              subtitle: 'Let\'s collaborate and bring your ideas to life!'
            }
          }
        }
      },
      fr: {
        translation: {
          title: 'Hey, Je suis Quentin Lestrade',
          subtitle: 'Lead (UX/UI)\nProduct Designer',
          stats: 'Années d\'expérience',
          stats2: 'Projets réalisés',
          stats3: 'Clients satisfaits',
          stats4: 'Grilles de pixel',
          social: 'LinkedIn',
          social2: 'Dribbble',
          projects: {
              title: 'Découvrez mes derniers projets',
              seeAll: 'Voir les projets',
          },
          parcelchain: {
            title: 'ParcelChain',
            subtitle: 'Réinventer la livraison.',
            description: 'En tant que Directeur Créatif, j\'ai joué un rôle clé dans la création de l\'identité et de l\'expérience utilisateur de ParcelChain, une plateforme innovante transformant la livraison du dernier kilomètre. Mon travail a englobé à la fois le branding et le design UX/UI, assurant une expérience cohérente et centrée sur l\'utilisateur. En connectant les commuters avec les expéditeurs de colis, ParcelChain rend les livraisons plus rapides, plus durables et plus accessibles.',
            brand: {
              title: 'Design de la marque',
              intro: 'La marque ParcelChain a été conçue pour communiquer visuellement les valeurs fondamentales de la plateforme : efficacité, sécurité et polyvalence. Chaque élément de design a été soigneusement étudié pour créer un logo qui soit non seulement mémorable mais aussi représentatif de l\'objectif de l\'application. Le résultat est une identité dynamique et moderne qui résonne avec les utilisateurs et la mission de ParcelChain.',
              callout1: 'Le premier élément est une vue isométrique d\'un colis, où seules deux faces sont visibles, laissant la troisième face vide pour créer une forme de «p» grâce à l\'espace blanc. Cet espace vide symbolise également l\'anonymat et la sécurité de la plateforme.',
              callout2: 'Le second élément est une virgule, qui donne un sentiment de vitesse au logo. Cette virgule est en fait un «C» inversé représentant les initiales «PC» de ParcelChain. La virgule sert également de référence à un boomerang, symbolisant le fait que les utilisateurs peuvent être à la fois expéditeurs et destinataires de colis, ainsi que livreurs.',
              conclusion: 'L\'utilisation d\'un colis dans le logo souligne l\'accent mis par l\'application sur la livraison de colis, tandis que la virgule ajoute une touche dynamique et renforce l\'idée d\'un service rapide et fiable.'
            },
            uxui: {
              title: 'Design UX/UI',
              description: 'La conception de l\'interface utilisateur de ParcelChain s\'est concentrée sur la création d\'une expérience fluide et intuitive pour tous les utilisateurs, qu\'ils envoient des colis, les reçoivent ou agissent en tant que livreurs. En utilisant Figma comme outil principal, j\'ai créé un prototype détaillé et interactif qui exploite des variables pour gérer le support multilingue, les statuts d\'expédition et diverses interactions utilisateur. Cette approche a assuré flexibilité et adaptabilité à différents cas d\'utilisation. Tout au long du processus, j\'ai collaboré étroitement avec les équipes de développement backend et frontend pour aligner la conception sur les exigences techniques, aboutissant à un prototype fiable et centré sur l\'utilisateur qui comble efficacement le fossé entre design et fonctionnalité.'
            }
          },
          dashstudio: {
            title: 'DashStudio',
            subtitle: 'Donner du pouvoir aux créatifs.',
            description: 'Avec DashStudio, j\'ai développé seul une application web sur mesure pour les photographes, vidéastes et designers afin de gérer efficacement leurs projets. Elle permet un partage sécurisé des médias avec les clients et simplifie les tâches comptables comme la création de devis et factures. Chaque fonctionnalité est conçue pour améliorer le flux de travail et approfondir les relations avec les clients.',
            development: {
              title: 'Développement',
              description: 'Le développement de DashStudio est une entreprise en solo, où j\'exploite la puissance des outils de pointe et de l\'intelligence artificielle pour donner vie à la plateforme. En combinant des technologies modernes telles que HTML5, CSS3, React, Node.js, Docker et Firebase, j\'ai construit une application robuste et évolutive conçue pour les créatifs. L\'IA joue un rôle clé dans l\'optimisation des flux de travail, la génération de code efficace et l\'assurance d\'une fonctionnalité transparente. Cette combinaison me permet de relever des défis complexes de manière indépendante tout en maintenant un haut niveau d\'efficacité et d\'innovation.'
            },
            brand: {
              title: 'Design de la marque',
              intro: 'L\'identité de marque DashStudio a été méticuleusement élaborée pour incarner l\'innovation et la fiabilité dans la gestion de projets créatifs. Chaque élément de design a été soigneusement choisi pour créer un langage visuel qui parle aussi bien aux créateurs professionnels qu\'à leurs clients, aboutissant à une marque à la fois moderne et digne de confiance.',
              callout1: 'Les trois bandes représentent les fonctionnalités principales de la plateforme : la gestion de projet, la collaboration client et les outils financiers. Leur angle ascendant symbolise la croissance et le progrès, tandis que leur couleur dorée évoque l\'excellence et le service premium.',
              callout2: 'Le cadre circulaire représente l\'unité et la complétude dans la gestion des flux de travail. Il rappelle également un objectif d\'appareil photo ou un viseur, établissant un lien avec les professionnels créatifs qui utilisent la plateforme. Le design épuré et minimaliste garantit que le logo reste efficace dans toutes les tailles et applications.',
              conclusion: 'Ensemble, ces éléments créent une identité de marque qui équilibre parfaitement professionnalisme et créativité, reflétant le rôle de DashStudio en tant qu\'outil essentiel pour les professionnels créatifs modernes.'
            },
            uxui: {
              title: 'Design UX/UI',
              description: 'Le design UX/UI de DashStudio a été conçu en pensant aux besoins des créatifs, assurant une expérience fluide et intuitive pour les photographes, vidéastes et graphistes. En se concentrant sur l\'ensemble du cycle de vie du projet - des devis à la livraison et aux paiements - le design simplifie des flux de travail complexes en une interface conviviale, permettant aux créatifs de se concentrer sur ce qu\'ils font de mieux.'
            }
          },
          davinci: {
            title: 'X-sphere | Da Vinci',
            subtitle: 'Procurer des émotions.'
          },
          streetfear: {
            title: 'Street Fear',
            subtitle: 'Transcender la réalité.'
          },
          why: {
            title: 'Pourquoi travailler avec moi ?',
            card1: {
              title: 'Approche centrée sur l\'utilisateur',
              description: 'Je place l\'utilisateur au cœur de chaque décision de design, créant des expériences qui résonnent vraiment.'
            },
            card2: {
              title: 'Vision créative à 360°',
              description: 'De la production vidéo au branding et au design UX/UI, j\'apporte une perspective holistique à chaque projet.'
            },
            card3: {
              title: 'Expertise multidisciplinaire',
              description: 'Mon ensemble de compétences diversifié me permet d\'aborder les défis sous plusieurs angles, offrant des solutions à la fois créatives et efficaces.'
            }
          },
          contact: {
            title: 'Créons quelque chose d\'incroyable ensemble.',
            subtitle: 'Que vous ayez des questions, souhaitiez discuter d\'un projet ou simplement parler de design, n\'hésitez pas à me contacter. Envoyez-moi un message !',
            form: {
              name: 'Nom',
              email: 'Email',
              message: 'Message',
              sending: 'Envoi en cours...',
              send: 'Envoyer le message',
              success: 'Message envoyé avec succès !',
              error: 'Une erreur est survenue. Veuillez réessayer.'
            }
          },
          footer: {
            title: 'Restons en contact',
            description: 'Je suis actuellement basé à Toulouse, France. Si vous souhaitez échanger ou discuter business autour d\'un café, n\'hésitez pas à me contacter.',
            download: 'Télécharger mon CV',
            contact: {
              title: 'Informations de contact',
              phone: '+33 6 34 53 17 48',
              email: 'contact@quentinlestrade.com',
              location: 'Toulouse, France'
            },
            social: {
              title: 'Connectez-vous avec moi',
              linkedin: 'LinkedIn',
              calendly: 'Calendly'
            }
          },
          projects: {
            title: 'Découvrez mes derniers projets',
            seeAll: 'Voir les projets',
            parcelchain: {
              title: 'Parcelchain',
              subtitle: 'Réinventer la livraison.'
            },
            dashstudio: {
              title: 'DashStudio',
              subtitle: 'Donner du pouvoir aux créatifs.'
            },
            davinci: {
              title: 'X-sphere | Da Vinci',
              subtitle: 'Procurer des émotions.'
            },
            streetfear: {
              title: 'Dans la rue',
              subtitle: 'Transcender la réalité.'
            },
            yourproject: {
              title: 'Votre projet',
              subtitle: 'Collaborons pour donner vie à vos idées !'
            }
          }
        }
      }
    }
  });

export default i18n;