// Home.js
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ParallaxProvider } from 'react-scroll-parallax';
import './home.css';
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from '@emailjs/browser';
import ReactDOM from 'react-dom';
import Spline from '@splinetool/react-spline';

// Images imports
import profile from './assets/img/profile.png';
import LinkedIn from './assets/img/Linkedin.svg';
import arrowDown from './assets/img/arrowDown.svg';
import Calendly from './assets/img/Calendly.svg';
import Card1 from './assets/img/Card1.png';
import Card2 from './assets/img/Card2.png';
import Card3 from './assets/img/Card3.png';
import Card4 from './assets/img/Card4.png';
import googleplay from './assets/img/googleplay.svg';
import appstore from './assets/img/appstore.svg';
import website from './assets/img/website.svg';
import logoLines from './assets/img/logoLines.png';
import logoLinesDash from './assets/img/logoLinesDash.png';
import dot from './assets/img/dot.svg';
import Mockup1 from './assets/img/Mockup1.png';
import Mockup2 from './assets/img/Mockup2.png';
import Mockup3 from './assets/img/Mockup3.png';
import Mockup4 from './assets/img/Mockup4.png';
import Mockup5 from './assets/img/Mockup5.png';
import BackgroundMockup from './assets/img/BackgroundMockup.png';
import dashstudioUI from './assets/img/dashstudioUI.png';
import gradientBg from './assets/img/Gradient.png';
import iphoneFooter from './assets/img/iphoneFooter.png';
import frFlag from './assets/img/fr-flag.svg';
import enFlag from './assets/img/en-flag.svg';

// Logos & bouton
import Google from './assets/img/Google.svg';
import NEAR from './assets/img/NEAR.svg';
import FranceBleu from './assets/img/FranceBleu.svg';
import France3 from './assets/img/France3.svg';
import SudOuest from './assets/img/SudOuest.svg';
import expand from './assets/img/expand.svg';

// Imports des icônes en haut du fichier
import cssIcon from './assets/img/css3.svg';
import htmlIcon from './assets/img/html5.svg';
import reactIcon from './assets/img/react.svg';
import nodeIcon from './assets/img/nodejs.svg';
import dockerIcon from './assets/img/docker.svg';
import databaseIcon from './assets/img/database.svg';
import firebaseIcon from './assets/img/firebase.svg';

// Import des CV
import ResumeEN from './assets/Resume_EN.pdf';
import ResumeFR from './assets/Resume_FR.pdf';

// Ajouter l'import en haut du fichier
import plusIcon from './assets/img/plusIcon.svg';

// Déplacer le VideoModal complètement en dehors du composant Home
const VideoModal = ({ isOpen, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const handleClose = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div 
      className="modal-overlay"
      onClick={handleClose}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999
      }}
    >
      <div 
        className="modal-content"
        style={{
          width: '80%',
          maxWidth: '960px',
          height: '80vh',
          maxHeight: '540px'
        }}
      >
        <div className="video-popup">
          <button 
            className="close-button" 
            onClick={() => onClose()}
            aria-label="Close video"
          >
            ✕
          </button>
          <iframe
            width="100%"
            height="500vh"
            src="https://www.youtube.com/embed/ApI_HMMbK2E?autoplay=1"
            title="X-sphère Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </div>,
    document.getElementById('modal-root') // Assurez-vous d'avoir cette div dans votre index.html
  );
};

function Home() {
  const { t, i18n } = useTranslation();

  const [scrollPosition, setScrollPosition] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [parcelchainSpline, setParcelchainSpline] = useState(null);
  const [dashstudioSpline, setDashstudioSpline] = useState(null);
  const [isParcelchainSplineVisible, setIsParcelchainSplineVisible] = useState(false);
  const [isDashstudioSplineVisible, setIsDashstudioSplineVisible] = useState(false);

  const parcelchainCalloutsLeftRef = useRef(null);
  const parcelchainCalloutsRightRef = useRef(null);
  const dashstudioCalloutsLeftRef = useRef(null);
  const dashstudioCalloutsRightRef = useRef(null);

  const [isWhyCardVisible, setIsWhyCardVisible] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const recaptchaRef = useRef(null);
  const form = useRef();

  // Simplifier la gestion du modal
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  // Ajouter ce state
  const [showProjectsButton, setShowProjectsButton] = useState(false);

  const [showSplinePopup, setShowSplinePopup] = useState(false);

  // Fonction pour générer un style aléatoire stable
  function generateRandomStyle(index) {
    // Détermine la colonne (0 à 3) basée sur l'index
    const column = index % 4;
    
    // Calcule la position horizontale basée sur la colonne
    const leftBase = column * 45;
    const leftPos = leftBase - 30;

    // Calcule la position verticale avec des espacements constants
    const rowSpacing = 450;
    const verticalOffset = (column % 2 === 0) ? 0 : 220;
    const topPos = (Math.floor(index / 4) * rowSpacing) + verticalOffset - 400;

    // Détermine l'angle basé sur la colonne
    const angle = column % 2 === 0 ? 45 : -45;

    return {
      top: `${topPos}px`,
      left: `${leftPos}%`,
      '--rand-rotate': `${angle}deg`,
      transform: `rotate(${angle}deg)`
    };
  }

  // Ajout du state pour le layout des téléphones
  const [phoneLayout] = useState(() => {
    const arr = [];
    for (let i = 0; i < 34; i++) {
      arr.push(generateRandomStyle(i));
    }
    return arr;
  });

  // Ajout de l'IntersectionObserver pour l'animation
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const element = entry.target;
            const angle = element.style.getPropertyValue('--rand-rotate');
            element.style.opacity = 1;
            element.style.transform = `translateY(0) rotate(${angle})`;
          }
        });
      },
      {
        threshold: 0.5,
        rootMargin: '-20px',
      }
    );

    const title = document.querySelector('.UI-parcelChain h2');
    const phones = document.querySelectorAll('.iphone-mockup-item');
    
    if (title) observer.observe(title);
    phones.forEach((phone, index) => {
      phone.style.transitionDelay = `${index * 0.4}s`;
      observer.observe(phone);
    });

    return () => observer.disconnect();
  }, []);

  // --------------------------------------------------
  // 1) Fade-in projets
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 1;
            entry.target.style.transform = 'translateY(0)';
          }
        });
      },
      {
        threshold: 0.5,
        rootMargin: '-20px',
      }
    );

    const title = document.querySelector('.projects-section h2');
    const cards = document.querySelectorAll('.project-card');
    
    if (title) observer.observe(title);
    cards.forEach((card, index) => {
      card.style.transitionDelay = `${index * 0.4}s`;
      observer.observe(card);
    });

    return () => observer.disconnect();
  }, []);

  // --------------------------------------------------
  // 2) IntersectionObserver : Spline
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsParcelchainSplineVisible(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0, rootMargin: '100px 0px' }
    );

    const splineSection = document.querySelector('.parcelchain-mockups');
    if (splineSection) observer.observe(splineSection);

    return () => observer.disconnect();
  }, []);

  // --------------------------------------------------
  // 3) Scroll-based Spline
  useEffect(() => {
    if (!parcelchainSpline) return;

    const handleScroll = () => {
      const splineSection = document.querySelector('.parcelchain-mockups');
      if (!splineSection) return;

      const rect = splineSection.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      
      let progress = (viewportHeight - rect.top) / (viewportHeight + rect.height);
      progress = Math.max(0, Math.min(1, progress));
      
      try {
        const totalFrames = 10000;
        const targetFrame = Math.floor(progress * totalFrames);
        
        parcelchainSpline.time = targetFrame;
        parcelchainSpline.requestRender();
        parcelchainSpline.renderMode = "manual";
      } catch (error) {
        console.error("Error updating ParcelChain Spline animation:", error);
      }
    };

    let ticking = false;
    const scrollListener = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          handleScroll();
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener('scroll', scrollListener, { passive: true });
    handleScroll();

    return () => window.removeEventListener('scroll', scrollListener);
  }, [parcelchainSpline]);

  // --------------------------------------------------
  // 4) onLoad Spline
  function onParcelchainSplineLoad(splineApp) {
    setParcelchainSpline(splineApp);
    if (splineApp) {
      splineApp.renderMode = "manual";
      splineApp.time = 0;
      splineApp.requestRender();
    }
  }

  // --------------------------------------------------
  // 5) Anim callouts

// Fonction de positions responsives pour chaque section
const getResponsivePositions = (sectionType, isLeft) => {
  console.log("getResponsivePositions called, window.innerWidth:", window.innerWidth);
  if (sectionType === 'dashstudio') {
    if (window.innerWidth > 1024) { // Desktop
      return isLeft
        ? { dot: { right: '155px', top: '-210px' }, line: { right: '-80px', top: '-200px', length: -250 } }
        : { dot: { left: '300px', top: '-340px' }, line: { left: '60px', top: '-250px', length: 250 } };
    } else if (window.innerWidth > 766) { // Tablet
      return isLeft
        ? { dot: { right: '115px', top: '-160px' }, line: { right: '-50px', top: '-150px', length: -200 } }
        : { dot: { left: '220px', top: '-260px' }, line: { left: '40px', top: '-180px', length: 200 } };
    } else { // Mobile
      return isLeft
        ? { dot: { right: '90px', top: '-185px' }, line: { right: '-30px', top: '-120px', length: -150 } }
        : { dot: { left: '200px', top: '-90px' }, line: { left: '25px', top: '-140px', length: 150 } };
    }
  } else if (sectionType === 'parcelchain') {
    if (window.innerWidth > 1024) { // Desktop
      return isLeft
        ? { dot: { right: '200px', top: '-230px' }, line: { right: '-100px', top: '-230px', length: -300 } }
        : { dot: { left: '280px', top: '-380px' }, line: { left: '280px', top: '-380px', length: 300 } };
    } else if (window.innerWidth > 766) { // Tablet
      return isLeft
        ? { dot: { right: '150px', top: '-170px' }, line: { right: '-18px', top: '-200px', length: -200 } }
        : { dot: { left: '220px', top: '-280px' }, line: { left: '100px', top: '-280px', length: 200 } };
    } else { // Mobile
      return isLeft
        ? { dot: { right: '-50px', top: '-280px' }, line: { right: '-150px', top: '-170px', length: -350 } }
        : { dot: { left: '10px', top: '-170px' }, line: { left: '150px', top: '-200px', length: 150 } };
    }
  }
  return { dot: {}, line: {} };
};

// Fonction d'animation des callouts avec valeurs indépendantes
const animateCallouts = (element, isLeft) => {
  console.log('animateCallouts called for:', element.className, 'isLeft:', isLeft);

  // Supprime tous les SVG existants
  element.querySelectorAll('svg').forEach(svg => svg.remove());

  // Détermine le type de section
  const sectionType = element.closest('.dashstudio-section')
    ? 'dashstudio'
    : element.closest('.parcelchain-section')
      ? 'parcelchain'
      : 'default';

  // Animation du texte
  const text = element.querySelector('p');
  if (text) {
    const positions = getResponsivePositions(sectionType, isLeft);
    Object.assign(text.style, {
      position: 'absolute',
      ...(isLeft
        ? { right: `${parseInt(positions.line.right) + 480}px` }
        : { left: `${parseInt(positions.line.left) + 120}px` }
      ),
      top: isLeft
        ? `${parseInt(positions.line.top) - 200}px`
        : `${parseInt(positions.line.top) + 80}px`,
      width: '300px',
      margin: '0',
      opacity: '0',
      transform: 'translateY(20px)',
      transition: 'all 0.5s ease'
    });
    setTimeout(() => {
      text.style.opacity = '1';
      text.style.transform = 'translateY(0)';
    }, 300);
  }

  // Création du SVG de base
  const newSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  Object.assign(newSvg.style, {
    position: 'absolute',
    overflow: 'visible'
  });

  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttribute('stroke', '#ccc');
  path.setAttribute('stroke-width', '3');
  path.setAttribute('fill', 'none');

  // Définit les valeurs du path selon la section et la taille d'écran
  if (sectionType === 'dashstudio') {
    if (window.innerWidth > 1024) { // Desktop
      if (isLeft) {
        path.setAttribute('d', 'M 0,0 h -380 v -40');
        Object.assign(newSvg.style, { right: '-140px', top: '-210px' });
      } else {
        path.setAttribute('d', 'M 0,0 h 220 v 30');
        Object.assign(newSvg.style, { left: '310px', top: '-340px' });
      }
    } else if (window.innerWidth > 766) { // Tablet
      if (isLeft) {
        path.setAttribute('d', 'M 0,0 h -250 v -30');
        Object.assign(newSvg.style, { right: '-180px', top: '-160px' });
      } else {
        path.setAttribute('d', 'M 0,0 h 160 v 30');
        Object.assign(newSvg.style, { left: '220px', top: '-260px' });
      }
    } else { // Mobile
      if (isLeft) {
        path.setAttribute('d', 'M 0,0 h -40 v -240');
        Object.assign(newSvg.style, { right: '-205px', top: '-185px' });
      } else {
        path.setAttribute('d', 'M 0,0 h -60 v 120');
        Object.assign(newSvg.style, { left: '205px', top: '-90px' });
      }
    }
  } else if (sectionType === 'parcelchain') {
    if (window.innerWidth > 1024) { // Desktop
      if (isLeft) {
        path.setAttribute('d', 'M 0,0 h -300 v -50');
        Object.assign(newSvg.style, { right: '-100px', top: '-230px' });
      } else {
        path.setAttribute('d', 'M 0,0 h 300 v 50');
        Object.assign(newSvg.style, { left: '280px', top: '-380px' });
      }
    } else if (window.innerWidth > 766) { // Tablet
      if (isLeft) {
        path.setAttribute('d', 'M 0,0 h -150 v -40');
        Object.assign(newSvg.style, { right: '-150px', top: '-170px' });
      } else {
        path.setAttribute('d', 'M 0,0 h 150 v 40');
        Object.assign(newSvg.style, { left: '220px', top: '-280px' });
      }
    } else { // Mobile
      if (isLeft) {
        path.setAttribute('d', 'M 0,0 h -30 v -260');
        Object.assign(newSvg.style, { right: '-150px', top: '-170px' });
      } else {
        path.setAttribute('d', 'M 0,0 h 4 v 300');
        Object.assign(newSvg.style, { left: '210px', top: '-280px' });
      }
    }
  }

  path.style.strokeDasharray = '350';
  path.style.strokeDashoffset = '350';
  path.style.transition = 'stroke-dashoffset 0.6s';

  newSvg.appendChild(path);
  element.appendChild(newSvg);

  setTimeout(() => { path.style.strokeDashoffset = '0'; }, 100);

  // Configuration du dot
const dotEl = element.querySelector('.dot');
if (dotEl) {
  Object.assign(dotEl.style, {
    position: 'absolute',
    width: '8px',
    height: '8px',
    backgroundImage: `url(${dot})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    opacity: '1',
    zIndex: '1',
    boxShadow: '0 0 10px rgba(204, 204, 204, 0.7)',
    transform: 'scale(1)',
    transition: 'all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1)',
    marginTop: '-4px'
  });
  const positions = getResponsivePositions(sectionType, isLeft);
  // Si une valeur 'right' est définie, on l'utilise ; sinon 'left'
  if (positions.dot.right) {
    dotEl.style.right = positions.dot.right;
  }
  if (positions.dot.left) {
    dotEl.style.left = positions.dot.left;
  }
  dotEl.style.top = positions.dot.top;
}
};

// Observer sur les callouts
useEffect(() => {
  const calloutElements = document.querySelectorAll('.callout-left, .callout-right');
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const element = entry.target;
          const isLeft = element.classList.contains('callout-left');
          if (!element.dataset.animated) {
            animateCallouts(element, isLeft);
            element.dataset.animated = true;
          }
          observer.unobserve(element);
        }
      });
    },
    { threshold: 0.1 }
  );

  calloutElements.forEach((element) => observer.observe(element));
  return () => observer.disconnect();
}, []);

  // --------------------------------------------------
  // 7) Stats hover
  const handleMouseMove = (e, statBox) => {
    const rect = statBox.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const xPercent = Math.round((x / rect.width) * 100);
    const yPercent = Math.round((y / rect.height) * 100);
    statBox.style.backgroundImage = `
      radial-gradient(
        circle at ${xPercent}% ${yPercent}%,
        rgba(150, 85, 166, 0.2) 38%,
        rgba(16, 15, 19, 0.2) 100%
      ),
      radial-gradient(
        circle at center,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.1) 100%
      )
    `;
  };

  // --------------------------------------------------
  // 8) Rotation auto
  useEffect(() => {
    const interval = setInterval(() => {
      setScrollPosition((prev) => {
        const setWidth = 5 * 740; 
        if (prev <= -setWidth) {
          return 0;
        }
        return prev - 1;
      });
    }, 20);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const phones = document.querySelectorAll('.iphone-mockup-item');
            phones.forEach((phone, index) => {
              phone.style.transitionDelay = `${index * 0.2}s`;
              phone.style.opacity = 1;
              phone.style.transform = `scale(1) rotate(var(--rand-rotate))`;
            });
            observer.disconnect();
          }
        });
      },
      {
        threshold: 0.2,
        rootMargin: '100px 0px'
      }
    );

    const gridContainer = document.querySelector('.iphone-mockups-grid');
    if (gridContainer) observer.observe(gridContainer);

    return () => observer.disconnect();
  }, []);


  // Observer pour DashStudio Spline
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsDashstudioSplineVisible(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0, rootMargin: '100px 0px' }
    );

    const splineSection = document.querySelector('.dashstudio-mockups');
    if (splineSection) observer.observe(splineSection);

    return () => observer.disconnect();
  }, []);

  // Fonction onLoad séparée pour chaque Spline
  function onDashstudioSplineLoad(splineApp) {
    setDashstudioSpline(splineApp);
    if (splineApp) {
      splineApp.renderMode = "manual";
      splineApp.time = 0;
      splineApp.requestRender();
    }
  }

  // Animation au scroll pour DashStudio Spline
  useEffect(() => {
    if (!dashstudioSpline) return;

    const handleScroll = () => {
      const splineSection = document.querySelector('.dashstudio-mockups');
      if (!splineSection) return;

      const rect = splineSection.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      
      let progress = (viewportHeight - rect.top) / (viewportHeight + rect.height);
      progress = Math.max(0, Math.min(1, progress));
      
      try {
        const totalFrames = 10000;
        const targetFrame = Math.floor(progress * totalFrames);
        
        dashstudioSpline.time = targetFrame;
        dashstudioSpline.requestRender();
        dashstudioSpline.renderMode = "manual";
      } catch (error) {
        console.error("Error updating DashStudio Spline animation:", error);
      }
    };

    let ticking = false;
    const scrollListener = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          handleScroll();
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener('scroll', scrollListener, { passive: true });
    handleScroll();

    return () => window.removeEventListener('scroll', scrollListener);
  }, [dashstudioSpline]);

  // Observer pour les callouts de DashStudio
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const isLeft = entry.target.classList.contains('callout-left');
            animateCallouts(entry.target, isLeft);
            
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.2 }
    );

    if (dashstudioCalloutsLeftRef.current) observer.observe(dashstudioCalloutsLeftRef.current);
    if (dashstudioCalloutsRightRef.current) observer.observe(dashstudioCalloutsRightRef.current);

    return () => observer.disconnect();
  }, []);

  // Observer pour la section Why Work With Me
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsWhyCardVisible(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.2 }
    );

    const whySection = document.querySelector('.why-work-with-me');
    if (whySection) observer.observe(whySection);

    return () => observer.disconnect();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Initialiser EmailJS avec useEffect
  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);
    console.log("EmailJS Public Key:", process.env.REACT_APP_EMAILJS_PUBLIC_KEY); // Pour déboguer
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      // Vérification du reCAPTCHA
      const token = await recaptchaRef.current.executeAsync();
      if (!token) {
        throw new Error('reCAPTCHA verification failed');
      }

      // Configuration des paramètres d'email
      const templateParams = {
        from_name: formData.name,
        from_email: formData.email,
        message: formData.message,
        to_name: "Quentin Lestrade",
        to_email: "contact@quentinlestrade.com"
      };

      // Envoi de l'email avec la clé publique explicite
      const result = await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY // Ajout explicite de la clé publique ici
      );

      if (result.text === 'OK') {
        setSubmitStatus('success');
        setFormData({ name: '', email: '', message: '' });
        recaptchaRef.current.reset();
      } else {
        throw new Error('Failed to send email');
      }
    } catch (error) {
      console.error('Error:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Ajoutez cette fonction de scroll vers le formulaire de contact
  const scrollToContactForm = () => {
    const contactSection = document.querySelector('.contact-section');
    if (contactSection) {
      const offset = 100; // ajustez cette valeur selon vos besoins
      const elementPosition = contactSection.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;
      
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  // Ajouter cette fonction pour changer la langue
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const elements = entry.target.querySelectorAll(
              '.parcelchain-title, .parcelchain-subtitle, .parcelchain-desc'
            );
            elements.forEach(element => {
              element.classList.add('visible');
            });
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.8,
        rootMargin: '-50px 0px'
      }
    );

    const heading = document.querySelector('.parcelchain-heading');
    if (heading) {
      observer.observe(heading);
    }

    return () => observer.disconnect();
  }, []);

  // Ajout de l'IntersectionObserver pour DashStudio heading
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const element = entry.target;
            if (element.classList.contains('dashstudio-title')) {
              element.style.opacity = '1';
              element.style.transform = 'translateY(0)';
            } else if (element.classList.contains('dashstudio-subtitle')) {
              element.style.opacity = '1';
              element.style.transform = 'translateY(0)';
            } else if (element.classList.contains('dashstudio-desc')) {
              element.style.opacity = '0.8';
              element.style.transform = 'translateY(0)';
            }
          }
        });
      },
      {
        threshold: 0.5,
        rootMargin: '-20px',
      }
    );

    const title = document.querySelector('.dashstudio-title');
    const subtitle = document.querySelector('.dashstudio-subtitle');
    const desc = document.querySelector('.dashstudio-desc');
    
    if (title) {
      title.style.transitionDelay = '0.2s';
      observer.observe(title);
    }
    if (subtitle) {
      subtitle.style.transitionDelay = '0.6s';
      observer.observe(subtitle);
    }
    if (desc) {
      desc.style.transitionDelay = '1.5s';
      observer.observe(desc);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const callouts = document.querySelectorAll('.callout-left, .callout-right');
      callouts.forEach(callout => {
        const isLeft = callout.classList.contains('callout-left');
        animateCallouts(callout, isLeft);
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Ajouter cet useEffect pour gérer l'apparition du bouton
  useEffect(() => {
    const handleScroll = () => {
      const projectsSection = document.querySelector('.projects-section');
      if (projectsSection) {
        const projectsBottom = projectsSection.getBoundingClientRect().bottom;
        setShowProjectsButton(projectsBottom < 0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Ajouter cette fonction pour le scroll
  const scrollToProjects = () => {
    const projectsSection = document.querySelector('.projects-section');
    if (projectsSection) {
      projectsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSplinePopup = () => {
    setShowSplinePopup(!showSplinePopup);
    // Empêcher le scroll quand le popup est ouvert
    if (!showSplinePopup) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  };

  return (
    <ParallaxProvider>
      <div className="language-switcher">
        <button
          className="language-button"
          onClick={() => changeLanguage('fr')}
          aria-label="Changer en français"
          style={{
            opacity: i18n.language === 'fr' ? 1 : 0.5
          }}
        >
          <img src={frFlag} alt="Français" />
        </button>
        <button
          className="language-button"
          onClick={() => changeLanguage('en')}
          aria-label="Switch to English"
          style={{
            opacity: i18n.language === 'en' ? 1 : 0.5
          }}
        >
          <img src={enFlag} alt="English" />
        </button>
      </div>
      <div className="container">
        {/* ---------------- Section HERO ---------------- */}
        <section className="hero-container">
          <div className="hero-content">

            <div className="headline">
              <div className="headline-text">
                <h2 className="title">{t('title')}</h2>
                <h1 className="subtitle">{t('subtitle')}</h1>
              </div>
              <img src={profile} alt="Quentin Lestrade" className="profile-pic" />
            </div>

            <div className="bottomrow">
              <div className="stats-container">
                {[
                  ['stats', '+7'], 
                  ['stats2', '+10'], 
                  ['stats3', '+15'], 
                  ['stats4', '4-8']
                ].map(([key, number]) => (
                  <div
                    key={key}
                    className="stat-box"
                    onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundImage = `
                        radial-gradient(
                          circle at center,
                          rgba(150, 85, 166, 0.2) 38%,
                          rgba(16, 15, 19, 0.2) 100%
                        ),
                        radial-gradient(
                          circle at center,
                          rgba(255, 255, 255, 0.1) 0%,
                          rgba(255, 255, 255, 0.1) 100%
                        )
                      `;
                    }}
                  >
                    <span className="stat-number">{number}</span>
                    <span className="stat-label">{t(key)}</span>
                  </div>
                ))}
              </div>

              <div className="socials">
                <a
                  href="https://www.linkedin.com/in/quentin-lestrade/"
                  target="_blank"
                  rel="noreferrer"
                  className="social-link"
                >
                  <img src={LinkedIn} alt="LinkedIn" className="social-icon" />
                </a>
                <a
                  href="https://calendly.com/contact-quentinlestrade"
                  target="_blank"
                  rel="noreferrer"
                  className="social-link"
                >
                  <img src={Calendly} alt="Calendly" className="social-icon" />
                </a>
              </div>
            </div>
            <img src={arrowDown} alt="Arrow Down" className="arrow-down" />
          </div>
        </section>

        {/* ---------------- Section PROJECTS ---------------- */}
        <section className="projects-section">
          <h1>{t('projects.title')}</h1>
          <div className="projects-grid">
            <div className="first-row">
              <div 
                className="project-card"
                style={{
                  backgroundColor: '#FDAA3B',
                  backgroundImage: `url(${Card1})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
                onClick={() => {
                  const parcelchainSection = document.querySelector('.parcelchain-section');
                  if (parcelchainSection) {
                    parcelchainSection.scrollIntoView({ behavior: 'smooth' });
                  }
                }}
              >
                <h3>{t('projects.parcelchain.title')}</h3>
                <p>{t('projects.parcelchain.subtitle')}</p>
              </div>
              <div 
                className="project-card"
                style={{
                  backgroundColor: '#1A74EE',
                  backgroundImage: `url(${Card2})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
                onClick={() => {
                  const dashstudioSection = document.querySelector('.dashstudio-section');
                  if (dashstudioSection) {
                    const offset = 100; // ajustez cette valeur selon vos besoins
                    const elementPosition = dashstudioSection.getBoundingClientRect().top;
                    const offsetPosition = elementPosition + window.pageYOffset - offset;
                    
                    window.scrollTo({
                      top: offsetPosition,
                      behavior: 'smooth'
                    });
                  }
                }}
              >
                <h3>{t('projects.dashstudio.title')}</h3>
                <p>{t('projects.dashstudio.subtitle')}</p>
              </div>
            </div>
            <div className="second-row">
              <div 
                className="project-card"
                style={{
                  backgroundColor: '#5038DD',
                  backgroundImage: `url(${Card3})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  cursor: 'pointer'
                }}
                onClick={() => setIsVideoModalOpen(true)}
              >
                <h3>{t('projects.davinci.title')}</h3>
                <p>{t('projects.davinci.subtitle')}</p>
              </div>
              <div 
                className="project-card"
                style={{
                  backgroundColor: '#FF761A',
                  backgroundImage: `url(${Card4})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
                onClick={handleSplinePopup}
              >
                <h3>{t('projects.streetfear.title')}</h3>
                <p>{t('projects.streetfear.subtitle')}</p>
              </div>
              <div 
                className="project-card"
                style={{ backgroundColor: '#A1466C', cursor: 'pointer' }}
                onClick={scrollToContactForm}
              >
                <div style={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  gap: '10px',
                  height: '24px',
                  marginBottom: '8px'
                }}>
                  <h3>{t('projects.yourproject.title')}</h3>
                  <img src={plusIcon} alt="plus" style={{ width: '24px', height: '24px', marginTop:-8 }} />
                </div>
                <p>{t('projects.yourproject.subtitle')}</p>
              </div>
            </div>
          </div>
        </section>

        {/* Gradient de transition supérieur */}
        <div className="gradient-transition"></div>

        {/* ---------------- Section PARCELCHAIN ---------------- */}
        <section className="parcelchain-section">
          <div className="parcelchain-heading">
            <h1 className="parcelchain-title">{t('parcelchain.title')}</h1>
            <h4 className="parcelchain-subtitle">{t('parcelchain.subtitle')}</h4>
            <p className="parcelchain-desc">
              {t('parcelchain.description')}
            </p>
          </div>
          
          <div className="parcelchain-mockups">
            {isParcelchainSplineVisible && (
              <Spline
                scene="https://prod.spline.design/4eT3EuTimbNVgJkr/scene.splinecode"
                onLoad={onParcelchainSplineLoad}
              />
            )}
          </div>

          <div className="app-badges">
            <a 
              href="https://downloads.parcelchain.app/getnow" 
              target="_blank" 
              rel="noreferrer"
            >
              <img src={appstore} alt="Download on the App Store" />
            </a>
            <a 
              href="https://downloads.parcelchain.app/getnow" 
              target="_blank" 
              rel="noreferrer"
            >
              <img src={googleplay} alt="Get it on Google Play" />
            </a>
            <a 
              href="https://parcelchain.co" 
              target="_blank" 
              rel="noreferrer"
            >
              <img src={website} alt="Check out the Website" />
            </a>
          </div>

          {/* ---------------- Bloc "Brand Design" ---------------- */}
          <div className="brand-design">
            <div className="brand-design-text">
              <h5 className="brand-design-title">{t('parcelchain.brand.title')}</h5>
              <p className="brand-design-intro">
                {t('parcelchain.brand.intro')}
              </p>
            </div>

            <div className="brand-logo-container">
              <img
                src={logoLines}
                alt="ParcelChain brand"
                className="brand-logo"
              />
              
              <div className="callout-left" ref={parcelchainCalloutsLeftRef}>
                <div className="dot"></div>
                <p>{t('parcelchain.brand.callout1')}</p>
              </div>

              <div className="callout-right" ref={parcelchainCalloutsRightRef}>
                <div className="dot"></div>
                <p>{t('parcelchain.brand.callout2')}</p>
              </div>
            </div>

            <p className="brand-design-conclusion">
              {t('parcelchain.brand.conclusion')}
            </p>

            {/* Carrousel 3D */}
            <div
              className="mockups-carousel-container"
              style={{
                backgroundImage: `
                  linear-gradient(
                    to bottom,
                    #110F17 0%,
                    transparent 25%,
                    transparent 75%,
                    #110F17 100%
                  ),
                  url(${BackgroundMockup})
                `,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
              }}
            >
              <div 
                className="mockups-carousel"
                style={{ 
                  transform: `translateX(${scrollPosition}px)`,
                  transition: scrollPosition === 0 ? 'none' : 'transform 0.1s linear'
                }}
              >
                {[...Array(3)].map((_, setIndex) => (
                  <div key={setIndex} className="mockups-set">
                    {[Mockup1, Mockup2, Mockup3, Mockup4, Mockup5].map((mockup, index) => (
                      <div 
                        key={`${setIndex}-${index}`}
                        className="mockup-item"
                      >
                        <img src={mockup} alt={`Mockup ${index + 1}`} />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* ------------- Partie UX/UI Design ------------- */}
          <div className="UI-parcelChain">
            <div className="UI-parcelChain-text">
              <h5 className="UI-parcelChain-title">{t('parcelchain.uxui.title')}</h5>
              <p className="UI-parcelChain-desc">
                {t('parcelchain.uxui.description')}
              </p>
            </div>

            {/* iPhones en disposition mosaïque */}
            <div
              className="UI-parcelChain-mockups"
              style={{
                height: isExpanded ? '3500px' : '1000px',
                transition: 'height 0.5s ease'
              }}
            >
              <div className="iphone-mockups-grid">
                {phoneLayout.map((styleObj, i) => {
                  const num = i + 1;
                  const srcPath = require(`./assets/img/mockupIphone/MockUp-${num}.png`);

                  return (
                    <img
                      key={num}
                      src={srcPath}
                      alt={`MockUp ${num}`}
                      className="iphone-mockup-item"
                      style={styleObj}
                    />
                  );
                })}
              </div>

              <button className="expand-button" onClick={() => setIsExpanded(!isExpanded)}>
                <img src={expand} alt="expand/contract" />
              </button>
            </div>

            {/* Logos sponsors / presse en-dessous */}
            <div className="logos-sponsor">
              <div className="logos-sponsor-left">
                <img src={Google} alt="Google" id='googleicon' />
              </div>
              <div className="logos-sponsor-right">
                <img src={NEAR} alt="NEAR" />
                <img src={FranceBleu} alt="France Bleu" />
                <img src={France3} alt="France 3" />
                <img src={SudOuest} alt="Sud Ouest" />
              </div>
            </div>
          </div>
        </section>

        {/* Gradient de transition inférieur */}
        <div className="gradient-transition"></div>

        {/* ---------------- Section DASHSTUDIO ---------------- */}
        <section className="dashstudio-section">
          <div className="dashstudio-heading">
            <h1 className="dashstudio-title">{t('dashstudio.title')}</h1>
            <h4 className="dashstudio-subtitle">{t('dashstudio.subtitle')}</h4>
            <p className="dashstudio-desc">
              {t('dashstudio.description')}
            </p>
          </div>
          
          <div className="dashstudio-mockups">
            {isDashstudioSplineVisible && (
              <Spline
                scene="https://prod.spline.design/Vp-fJ3PcdERFwN17/scene.splinecode"
                onLoad={onDashstudioSplineLoad}
              />
            )}
          </div>

          <div className="app-badges">
            <a 
              href="https://dashstudio.io/" 
              target="_blank" 
              rel="noreferrer"
            >
              <img src={website} alt="Check out the Website" />
            </a>
          </div>

          {/* ---------------- Bloc "Brand Design" ---------------- */}
          <div className="brand-design">
            <div className="brand-design-text">
              <h5 className="brand-design-title">{t('dashstudio.brand.title')}</h5>
              <p className="brand-design-intro">
                {t('dashstudio.brand.intro')}
              </p>
            </div>

            <div className="brand-logo-container">
              <img
                src={logoLinesDash}
                alt="DashStudio brand"
                className="brand-logo"
              />
              
              <div className="callout-left" ref={dashstudioCalloutsLeftRef}>
                <div className="dot"></div>
                <p>{t('dashstudio.brand.callout1')}</p>
              </div>

              <div className="callout-right" ref={dashstudioCalloutsRightRef}>
                <div className="dot"></div>
                <p>{t('dashstudio.brand.callout2')}</p>
              </div>
            </div>

            <p className="brand-design-conclusion">
              {t('dashstudio.brand.conclusion')}
            </p>

          
          </div>

          {/* ------------- Partie UX/UI Design ------------- */}
          <div 
            className="UI-dashstudio"
            style={{
              backgroundImage: `url(${dashstudioUI})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <div className="UI-dashstudio-overlay"></div>
            <div className="UI-dashstudio-content">
              <div className="UI-dashstudio-text">
                <h5 className="UI-parcelChain-title">{t('dashstudio.uxui.title')}</h5>
                <p className="UI-parcelChain-desc">
                  {t('dashstudio.uxui.description')}
                </p>
              </div>

              {/* iPhones en disposition mosaïque */}
              <div
                className="UI-dashstudio-mockups"
                style={{
                  height: isExpanded ? '4000px' : '900px',
                  transition: 'height 0.5s ease'
                }}
              >
              </div>
            </div>
            <div className="development-container">
              <h5 className="development-title">{t('dashstudio.development.title')}</h5>
              <p className="development-desc">
                {t('dashstudio.development.description')}
              </p>

              <div className="development-icons">
                <img src={cssIcon} alt="CSS3" />
                <img src={htmlIcon} alt="HTML5" />
                <img src={reactIcon} alt="React" />
                <img src={nodeIcon} alt="Node.js" />
                <img src={dockerIcon} alt="Docker" />
                <img src={databaseIcon} alt="Database" />
                <img src={firebaseIcon} alt="Firebase" />
                </div>
            </div>
          </div>
        </section>

        {/* Gradient de transition supérieur */}
        <div className="gradient-transition"></div>

        {/* ---------------- Section WHY WORK WITH ME ---------------- */}
      <section className="why-work-with-me">
        <h1 className="why-title">{t('why.title')}</h1>
        <div className="why-grid">
          <div className="why-column-left">
            <div 
              className="why-card horizontal-layout"
              style={{ '--gradient-bg': `url(${gradientBg})` }}
            >
              <div className="why-card-content">
                <h3 className="why-card-title">{t('why.card1.title')}</h3>
                <p className="why-card-text">
                  {t('why.card1.description')}
                </p>
              </div>
              <div className="why-icon-container eye-container">
                {isWhyCardVisible && (
                  <Spline scene="https://prod.spline.design/arcXM5t2gG6Vj2lS/scene.splinecode" />
                )}
              </div>
            </div>

            <div 
              className="why-card horizontal-layout"
              style={{ '--gradient-bg': `url(${gradientBg})` }}
            >
              <div className="why-card-content">
                <h3 className="why-card-title">{t('why.card2.title')}</h3>
                <p className="why-card-text">
                  {t('why.card2.description')}
                </p>
              </div>
              <div className="why-icon-container spiral-container">
                {isWhyCardVisible && (
                  <Spline scene="https://prod.spline.design/2RpM9FXGSAPCOy1i/scene.splinecode" />
                )}
              </div>
            </div>
          </div>

          <div className="why-column-right">
            <div 
              className="why-card vertical-layout"
              style={{ '--gradient-bg': `url(${gradientBg})` }}
            >
              <div className="why-card-content-vertical">
                <h3 className="why-card-title">{t('why.card3.title')}</h3>
                <p className="why-card-text">
                  {t('why.card3.description')}
                </p>
              </div>
              <div className="why-icon-container pill-container">
                {isWhyCardVisible && (
                  <Spline scene="https://prod.spline.design/xQ3qexbXHXt0guNo/scene.splinecode" />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-section">
        <div className="contact-content">
          <div className="contact-text">
            <h1 className="contact-title">{t('contact.title')}</h1>
            <h4 className="contact-subtitle">
              {t('contact.subtitle')}
            </h4>
          </div>
          
          <form ref={form} className="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <input 
                type="text" 
                name="name"
                placeholder={t('contact.form.name')} 
                required 
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <input 
                type="email" 
                name="email"
                placeholder={t('contact.form.email')} 
                required 
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <textarea 
                name="message"
                placeholder={t('contact.form.message')} 
                required 
                rows="6"
                value={formData.message}
                onChange={handleInputChange}
              ></textarea>
            </div>
            
            <div className="recaptcha-container">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LdD48cqAAAAAOcuz2EoKCEsczTHowcBKztwbP17"
                size="invisible"
              />
            </div>

            <button 
              type="submit" 
              className="send-button" 
              style={{ '--gradient-bg': `url(${gradientBg})` }}
              disabled={isSubmitting}
            >
              <span>
                {isSubmitting ? t('contact.form.sending') : t('contact.form.send')}
              </span>
            </button>

            {submitStatus === 'success' && (
              <div className="submit-status success">
                {t('contact.form.success')}
              </div>
            )}
            
            {submitStatus === 'error' && (
              <div className="submit-status error">
                {t('contact.form.error')}
              </div>
            )}
          </form>
        </div>
      </section>

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-left">
            <h6 className="footer-title">{t('footer.title')}</h6>
            <p className="footer-text">
              {t('footer.description')}
            </p>
            <button 
              className="footer-button"
              onClick={() => {
                const resumeFile = i18n.language === 'fr' ? ResumeFR : ResumeEN;
                const link = document.createElement('a');
                link.href = resumeFile;
                link.download = i18n.language === 'fr' ? 'Resume_FR.pdf' : 'Resume_EN.pdf';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
            >
              {t('footer.download')} <span>📄</span>
            </button>
          </div>

          <div className="footer-middle">
            <h6 className="footer-subtitle">{t('footer.contact.title')}</h6>
            <p className="footer-info">
              📱 <a href="tel:+33634531748">{t('footer.contact.phone')}</a>
           </p>
            <p className="footer-info">
              ✉️ <a href="mailto:contact@quentinlestrade.com">{t('footer.contact.email')}</a>
            </p>
            <p className="footer-info">
              📍 {t('footer.contact.location')}
            </p>
          </div>

          <div className="footer-right">
            <img 
              src={iphoneFooter} 
              alt="iPhone Footer" 
              className="footer-right-background"
            />
            <h6 className="footer-subtitle">{t('footer.social.title')}</h6>
            <a href="https://www.linkedin.com/in/quentin-lestrade/" target="_blank" rel="noreferrer" className="footer-link">
              <img src={LinkedIn} alt="LinkedIn" /> {t('footer.social.linkedin')}
            </a>
            <a href="https://calendly.com/contact-quentinlestrade" target="_blank" rel="noreferrer" className="footer-link">
              <img src={Calendly} alt="Calendly" /> {t('footer.social.calendly')}
            </a>
          </div>
        </div>
      </footer>
      </div>
      <VideoModal 
        isOpen={isVideoModalOpen} 
        onClose={() => setIsVideoModalOpen(false)} 
      />
      {showProjectsButton && (
        <button
          className="floating-projects-button"
          onClick={scrollToProjects}
          aria-label={t('projects.seeAll')}
        >
          {t('projects.seeAll')} 
          <img 
            src={arrowDown} 
            alt="Up" 
            className="button-icon rotated"
          />
        </button>
      )}
      {showSplinePopup && (
        <div className="popup-overlay" onClick={handleSplinePopup}>
          <div className="popup-content" onClick={e => e.stopPropagation()}>
            <button className="close-button" onClick={handleSplinePopup}>
              ✕
            </button>
            <div className="spline-container">
              <Spline 
                scene="https://prod.spline.design/cmZzSvw1D2W1uCjy/scene.splinecode"
              />
            </div>
          </div>
        </div>
      )}
    </ParallaxProvider>
  );
}

export default Home;