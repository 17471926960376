import React from 'react';
import './App.css';
import './i18n'; // initialise i18next (important)
import { useTranslation } from 'react-i18next';
import Home from './home'; // changement de 'Home' à 'home'

function App() {
  // tu peux récupérer la fonction t() ici si besoin
  const { t } = useTranslation();

  return (
    <div className="App">
      {/* Affiche simplement la section Home */}
      <Home />
    </div>
  );
}

export default App;